import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    canManager:true,
    canAdmin:true,
    canTalent:true,
    meta: {
      title: 'ホーム',
    }
  },
  {
    path: '/login',
    name: 'login',
    canManager:true,
    canAdmin:true,
    canTalent:true,
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    meta: {
      title: 'ログインページ',
    }
  },
  {
    path: '/menu',
    name: 'menu',
    canManager:true,
    canAdmin:true,
    canTalent:true,
    component: () => import(/* webpackChunkName: "about" */ '../views/MenuView.vue'),
    meta: {
      title: 'メニュー',
    }
  },
  {
    path: '/money',
    name: 'money',
    canManager:true,
    canAdmin:true,
    canTalent:false,
    component: () => import(/* webpackChunkName: "about" */ '../views/MoneyManagementView.vue'),
    meta: {
      title: '入出金管理',
    }
  },
  {
    path: '/insentive',
    name: 'insentive',
    canManager:true,
    canAdmin:true,
    canTalent:true,
    component: () => import(/* webpackChunkName: "about" */ '../views/InsentiveView.vue'),
    meta: {
      title: 'チェキ券画像登録',
    }
  },
  {
    path: '/insentivelist',
    name: 'insentivelist',
    canManager:true,
    canAdmin:true,
    canTalent:true,
    component: () => import(/* webpackChunkName: "about" */ '../views/InsentiveListView.vue'),
    meta: {
      title: 'チェキ券画像確認',
    }
  },
  {
    path: '/insentiveDetail',
    name: 'insentiveDetail',
    canManager:true,
    canAdmin:true,
    canTalent:true,
    component: () => import(/* webpackChunkName: "about" */ '../views/InsentiveDetailView.vue'),
    meta: {
      title: 'チェキ券画像詳細',
    }
  },
  {
    path: '/pocket',
    name: 'pocket',
    canManager:true,
    canAdmin:false,
    canTalent:false,
    component: () => import(/* webpackChunkName: "about" */ '../views/pocketView.vue'),
    meta: {
      title: 'ポケットマネー一覧',
    }
  },
  {
    path: '/analysis',
    name: 'analysis',
    canManager:true,
    canAdmin:false,
    canTalent:false,

    component: () => import(/* webpackChunkName: "about" */ '../views/AdminAnalysisView.vue'),
    meta: {
      title: '月別データ参照',
    }
  },
  {
    path: '/adminUserRegist',
    name: 'adminUserRegist',
    canManager:true,
    canAdmin:false,
    canTalent:false,

    component: () => import(/* webpackChunkName: "about" */ '../views/AdminUserRegistView.vue'),
    meta: {
      title: 'ユーザー登録',
    }
  },
  {
    path: '/test',
    name: 'test',
    canManager:true,
    canAdmin:true,
    canTalent:true,
    component: () => import(/* webpackChunkName: "about" */ '../views/testSlot.vue'),
    meta: {
      title: 'test',
    }
  },
  {
    path: '/logout',
    name: 'loguout',
    component: () => import(/* webpackChunkName: "about" */ '../views/LogoutView.vue'),
    meta: {
      title: 'ログアウト',
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
