import axios from 'axios'

export default {
    data () {
      return {
        // 定数定義
        ADMIN:1,
        MANAGER:2,
        TALENT:3,
        // 
        roleName: '',
        roleId:null,
        userId:null,
        routerList:[]
      }
    },
    methods: {
      //------------------------------------------------------------------------------//
      //  関数名：getData                                                             //
      //  引数：urlStr,aug1,aug2,aug3,aug4                                            //
      //  戻値：dataArray                                                             //
      //  備考：URLと引数を渡してAPIをたたく関数                                       //
      //        引数で渡したaug1-4はdata配列で渡される                                 //
      //        API側で$this->request->getPost("aug1")とすることで値を取得できる       //
      //------------------------------------------------------------------------------//
      async getData (urlStr,aug1,aug2,aug3,aug4) {

        var dataArray = new Array()

        // リクエストヘッダー定義
        const headers = {
            accept: "application/json",
            "Content-Type": "multipart/form-data"
        };
      
        // データ配列定義
        const data = {
          aug1 : aug1,
          aug2 : aug2,
          aug3 : aug3,
          aug4 : aug4,
        }
        // 非同期通信でAPIをたたく
        await axios
          .post("https://pmb-test.38-197.execute.jp/getdata/" + urlStr,data,{headers:headers})
          .then(response => {

            // 取得したresponseオブジェクトの中身は以下の形式
            // response obj
            // ├─config
            // ├─data
            // │ └─message
            // │   └─APIで取得した値
            // ├─header
            // :

            // foreachはオブジェクト展開の際には使えない。
            // そのため、受け取ったオブジェクトのkeyを取得
            // オブジェクトのkeyを配列として取得してループで回す

            // オブジェクトのdataのmessage配列のkey取得
            var keys = Object.keys(response.data.message)

            // keysでループ
            keys.forEach((key) =>

              // dataArray配列へ要素を追加するために、message配列のkeyを指定して値を取得する
              dataArray.push(response.data.message[key])
            )
        })
        .catch(error => {
        console.log(error)
        console.log("error")
        })

        // 取得した値を返す
        return dataArray
      },
      //------------------------------------------------------------------------------//
      //  関数名：checkLogin                                                          //
      //  引数：なし                                                                  //
      //  戻値：なし                                                                  //
      //  備考：ログインしたときcookieにpersistense codeが保存される                   //
      //        保存されているpersistense codeを使ってroleIdを取得する                 //
      //------------------------------------------------------------------------------//
      async checkLogin () {

        //cookieからpersistense を取得
        var cookies = document.cookie;              // 全てのcookieを取り出して
        var cookiesArray = cookies.split(';');      // ;で分割し配列に
        var code = ""     
            
        for(var c of cookiesArray){                 // 一つ一つ取り出して
            var cArray = c.split('=');              // さらに=で分割して配列に
            if( cArray[0] == 'cartalyst_sentinel'){ // 取り出したいkeyと合致したら

                //先頭の3文字がいらないので削除してcode変数に格納
                code = cArray[1].slice( 3 );  
            }
        }

        if (code !="") {

          // persistense codeからユーザーIDとroleを取得
          // this.userInfo [0]:ユーザーID [1]:role ID
          this.userInfo = await this.getData("get_user_by_persistance",code)
          
          this.userId = this.userInfo[0]
          this.roleId = this.userInfo[1]

          if (this.roleId == 1 ) {
            this.roleName = "管理者"
          
          }
          else if (this.roleId == 2 ){
            this.roleName = "マネージャー"
          
          }
          else if (this.roleId == 3 ){
            this.roleName = "タレント"
          
          }
          else{
            this.roleName = "非ログイン"
          
          }
        }
      },
      //------------------------------------------------------------------------------//
      //  関数名：checkLogin                                                          //
      //  引数：なし                                                                  //
      //  戻値：なし                                                                  //
      //  備考：ログインしたときcookieにpersistense codeが保存される                   //
      //        保存されているpersistense codeを使ってroleIdを取得する                 //
      //------------------------------------------------------------------------------//
      async getUserID () {

        //cookieからpersistense を取得
        var cookies = document.cookie;              // 全てのcookieを取り出して
        var cookiesArray = cookies.split(';');      // ;で分割し配列に
        var code = ""     
            
        for(var c of cookiesArray){                 // 一つ一つ取り出して
            var cArray = c.split('=');              // さらに=で分割して配列に
            if( cArray[0] == 'cartalyst_sentinel'){ // 取り出したいkeyと合致したら

                //先頭の3文字がいらないので削除してcode変数に格納
                code = cArray[1].slice( 3 );  
            }
        }

        // persistense codeからユーザーIDとroleを取得
        // this.userInfo [0]:ユーザーID [1]:role ID
        this.userInfo = await this.getData("get_user_by_persistance",code)
      
        return this.userInfo[0]
      },
      //------------------------------------------------------------------------------//
      //  関数名：checkLogin                                                          //
      //  引数：なし                                                                  //
      //  戻値：なし                                                                  //
      //  備考：ログインしたときcookieにpersistense codeが保存される                   //
      //        保存されているpersistense codeを使ってroleIdを取得する                 //
      //------------------------------------------------------------------------------//
      async viewRoleManagement () {

        // 現在のパス
        console.log(this.$route.path)

        // 現在の画面がログイン画面でない時
        if(this.$route.path != "/login" ){

          // 存在する画面すべてを取得
          const routerObj = this.$router.options.routes

          // ログイン者のroleIdを権限と照合
          // オブジェクトのdataのmessage配列のkey取得
          var keys1 = Object.keys(routerObj)

          console.log(routerObj)

          // routerの数だけループ
          for(let i =0; i < keys1.length; i++ ){

            // 現在のパスと合致するrouterを取得
            if(this.$route.path == routerObj[i].path){

              // 現在のrouterの情報を取得
              this.routerList.push(routerObj[i])
            }
          }                  



          // ログイン者のroleIdからチェックする権限を決定
          if  (this.roleId == this.ADMIN) {
            console.log('ADMIN')
            // 管理者の閲覧権限がないrouterのとき
            if (this.routerList.canAdmin == false){
              // ログイン画面へリダイレクト
              this.$router.push("/login")
            }
          }
          // ログイン者がマネージャーの時
          else if  (this.roleId == this.MANAGER) {
            console.log('MANAGER')
            // 管理者の閲覧権限がないrouterのとき
            if (this.routerList.canManager == false){
              // ログイン画面へリダイレクト
              this.$router.push("/login")
            }
          }
          // ログイン者がタレントの時
          else if  (this.roleId == this.TALENT) {
            console.log('TALENT')
            console.log(this.routerList[0])
            // 管理者の閲覧権限がないrouterのとき
            if (this.routerList[0].canTalent == false){
              // ログイン画面へリダイレクト
              this.$router.push("/login")
            }
          }
          else {

            // 非ログインの時はログイン画面へリダイレクト
            console.log("ログイン画面へリダイレクト")
            this.$router.push("/login")
          }

        }
      },
      // 文字列から ArrayBuffer への変換

      string_to_buffer(src) {
        return (new Uint16Array([].map.call(src, function(c) {
        return c.charCodeAt(0)
      }))).buffer;
      },

      // ArrayBuffer から文字列への変換
      buffer_to_string(buf) {
        return String.fromCharCode.apply("", new Uint8Array(buf))
      },
      large_buffer_to_string(buf) {
        var tmp = [];
        var len = 1024;
        for (var p = 0; p < buf.byteLength; p += len) {
          tmp.push(this.buffer_to_string(buf.slice(p, p + len)));
        }
        return tmp.join("");
      },
      arrayBufferToBinaryString(arrayBuffer) {
        let binaryString = "";
        const bytes = new Uint8Array(arrayBuffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binaryString += String.fromCharCode(bytes[i]);
        }
        return binaryString
      }
    }
  }
