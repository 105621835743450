<template>
  <v-card
    class="mx-auto"
    max-width="500"
  >
   <v-container>
    <v-row 
    no-gutters
    align="center"
    >
      <!-- <v-col
       v-for="(item, i) in items"
       :key="i"
       cols="6"
       align="center"
      >
        <div>
          <v-card
            :color="item.color"
            class="menu-cards"
            dark
            @click="cardLink(item.link)"
          >
            <v-img
              :src="item.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title v-text="item.title"></v-card-title>
            </v-img>
          </v-card>
          </div>
      </v-col> -->
      </v-row>
    </v-container>
  </v-card>
</template>

<style>
.menu-cards{
  padding: 0%;
  margin: 2%;
  height: 200px;
  align-items: centers;
  align-content: center;
  align-self: center;
}

</style>

<script>
// @ is an alias to /src


export default {
  
  name: 'HomeView',
  components: {

  },
  data: () => ({
    items: [
      {
        color: '#1F7087',
        title: 'マネージャー',
        link: '/login',
        src: './img/manager.png'
      },
      {
        color: '#952175',
        title: 'タレント',
        link: '/login',
        src: './img/idol.png'
      },
      
    ],
    responseArray:[]
  }),
  mounted() {
    this.$router.push("/login")
  },
  methods :{
    cardLink(link){
      this.$router.push(link)
      console.log("cardLink")
    },
  }
}
</script>
