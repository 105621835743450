<template>
  <v-app>
  <div id="app">
    
    <v-card>
      <v-toolbar
      color="indigo"
      class="justify-center"
      dark
    >
      <v-app-bar-nav-icon @click.stop="mydrawer = !mydrawer"></v-app-bar-nav-icon>
      <v-row>
      <v-col>
        <p   class="title">
          {{menuTitle}}
        </p> 
      </v-col>
      <v-col
 v-if="this.roleId == this.MANAGER"
       class="money"
       >
       <v-row      >
       所持金
       </v-row>
        <v-row>
       {{pocketMoney}}円
       </v-row>
             </v-col>
      </v-row>
      <v-spacer></v-spacer>
    </v-toolbar>
    </v-card>
        <!-- ここがdrawerコンポーネントを呼び出している部分 -->
    <v-navigation-drawer
      v-model="mydrawer"
      absolute
      bottom
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="mygroup"
          active-class="cyan--text text--accent-4"
        >
          <v-list-item
           v-for="(item, i) in items"
           :key="i"
           cols="16"
           >
            <v-list-item-title
             @click="cardLink(item.link)">
              {{item.title}}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <router-view/>
    
  </div>
  </v-app>
</template>

<style>
.title{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.money{
  font-size: 100%;
    margin: auto;
}
</style>

<script>
  import util from './mixin/utility'
  import axios from 'axios'
  export default {
    mixins: [util],
    data: () => ({

      // 
      sumArray:[],
      pocketMoneyObj:null,
      menuTitle:"menu",
      groupId:0,
      pocketMoney:0,
      mydrawer: false,
      mygroup: null, // ← 初期値はnullではなく、文字列でも数値でも良いです
      items: [
        {
          color: '#1F7087',
          title: 'メニュー',
          link: '/menu',
        },
        {
          color: '#952175',
          title: 'test',
          link: '/test',
        },
        {
          color: '#952175',
          title: 'ログイン',
          link: '/login',
        },
        {
          color: '#952175',
          title: 'ログアウト',
          link: '/logout',
        }
      ],
    }),
    created() {
      this.menuTitle = localStorage.getItem('pageTitle')

    },
    methods: {
      cardLink(link){
        // 現在いるページのリンクをクリックしたときはエラーキャッチする
        this.$router.push(link).catch(() => {
          // エラーキャッチ時の処理 メニュー画面を非表示にする
          this.mydrawer = false
        })
        console.log("cardLink")
      },
      async logout() {
        console.log("rtetaasd")
        document.cookie = "cartalyst_sentinel="+ encodeURIComponent("")
        await axios
        .get("https://pmb-test.38-197.execute.jp/test/logout")
        .then(response => {
          console.log(response.data.message)
           console.log(self.loginStatus)
        
          console.log("then")
        })
        .catch(error => {
          console.log(error)
           console.log("error")
        })
      },
      setMeta(route){
      // タイトルを設定
      if(route.meta.title){
        this.menuTitle = route.meta.title;
        document.title = this.menuTitle;
      }
      // ディスクリプションを設定
      if(route.meta.desc){
        let setDesc = route.meta.desc;
        document.querySelector("meta[name='description']").setAttribute('content', setDesc)
      }
      },
      async checkRoleId(){
        await this.checkLogin ()
        console.log('this.roleId')
        console.log(this.roleId)

        // ページを見る権限があるかチェックする関数を呼び出す
        this.viewRoleManagement()
      }
    },
    mounted(){
      let route = this.$route;
      this.setMeta(route);
    },
    async updated(){
      // ログイン者がマネージャーのとき
      if (this.roleId == this.MANAGER){

        // サーバー側ですべての入出金を洗い出してマネージャーごとのポケットマネーを配列で返す。
        this.pocketMoneyObj = await this.getData("get_all_pocket_money")

        // 配列を定義
        this.pocketArray = []

        // ポケットマネーがログイン者のIDに紐づくものを取得
        this.pocketMoneyObj.forEach(key => {
          if(key.managerId == this.userId){
            this.pocketArray.push({
              Name:    key.managerName,
              Income:  key.income,
              Expense: key.expense,
              Pocket:  key.pocket,
            })
          }
        })
        this.pocketMoney = this.pocketArray[0].Pocket
      }
    },
    watch: { 
    '$route' (route, from) {
      console.log(route,from)
      this.setMeta(route);

      // URLが変わるたびに役割をチェックする
      this.checkRoleId()
    }
  }
}
</script>